import { NextPage } from "next";
import tw from "twin.macro";
import { Layout } from "src/features/Layout";
import NotFoundPage from "src/shared/components/NotFoundPage";
import { CmsContentSection } from "src/features/CmsContentSection";
import {
  GetHomepageDataQuery,
  GetLayoutDataQuery,
} from "src/generated/datocms-types";
import { SITE_URL } from "src/constants";
import Head from "next/head";
import { useMutation } from "react-query";
import { CollectionProductsMap } from "src/shared/types";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Snackbar, SnackbarContent } from "@mui/material";
import { useSession } from "next-auth/react";
import { cartApi } from "../Cart/state";
import { CartDiscountCodesUpdateType } from "../Cart/types";
import { cartCookieConfig } from "../Cart/constants";

export type Props = {
  collectionProducts: CollectionProductsMap;
  pageData: GetHomepageDataQuery;
  layoutData: GetLayoutDataQuery;
  preview: boolean;
};
const HomePage: NextPage<Props> = ({
  pageData,
  layoutData,
  preview,
  collectionProducts,
}) => {
  const router = useRouter();
  const [cartId, setCardId] = useState<string>("");
  const [discountCodeLP, setDiscountCodeLP] = useState<string>("");
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const { data: session } = useSession();
  const mutationDiscountCodeUpdate = useMutation(
    (discountCodeUpdateData: CartDiscountCodesUpdateType) =>
    cartApi
        .url("/discount_code_update")
        .post({
          cartId: discountCodeUpdateData.cartId,
          discountCodes: discountCodeUpdateData.discountCodes,
        })
        .json()
  );
  useEffect(() => {
    const discountCode: string =
      router.query.discountCode?.toString() || "";
      if (discountCode.length)
      {
        if (!session) {
          setSnackbarMessage("Please log in to redeem The Coupon.");
          setSnackbarOpen(true);
          return;
        } 
        const cartIdC = Cookies.get(cartCookieConfig.name) ?? "";
        setCardId(cartIdC);
        setDiscountCodeLP(discountCode);
        }
       
     
  },[router.query.discountCode, session]);
  
   useEffect(() => {
      if (cartId.length && session)
      {
        let discountCodes: string[] = [];
        discountCodes.push(discountCodeLP);
           mutationDiscountCodeUpdate.mutate(
            {
              cartId,
              discountCodes,
            },
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onSuccess: (data: any) => {
                setSnackbarMessage("Your BFF discount will be applied at checkout!");
                setSnackbarOpen(true);
                router.push("/");
              },
              onError: (e) => {
                setSnackbarMessage("Your coupon could not be applied, please try again.");
                setSnackbarOpen(true);
              },
            }
          ); 
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cartId, discountCodeLP]); 
  
  
  const { homepage: page } = pageData;
  const canonicalUrl = `${SITE_URL}/`;
  if (!page) {
    return <NotFoundPage />;
  }

  return (
    <Layout preview={preview} meta={page.seo} layoutData={layoutData}>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      {snackbarOpen && (
        <Snackbar
        css={tw`bg-white`}
        open={snackbarOpen}
          autoHideDuration={6000}
          anchorOrigin={{vertical:"top", horizontal:"right"}}
        onClose={()=>setSnackbarOpen(false)}
        >
          <SnackbarContent style={{
      backgroundColor:"#d3d3d3",
    }}
            message={<span id="client-snackbar" css={tw`text-black font-roboto text-sm`}>{ snackbarMessage}</span>}
  />
      </Snackbar>
      )}
      
      {page.sections.map((section) => (
        <CmsContentSection
          key={section.id}
          data={section}
          collectionProducts={collectionProducts}
        />
      ))}
    </Layout>
  );
};
export default HomePage;
