import HomePage, { Props } from "src/features/HomePage";
import { GetStaticProps } from "next";
import { initializeApollo } from "src/services/datocms";
import {
  BlockProductCollectionRecord,
  GetHomepageDataDocument,
  GetHomepageDataQuery,
  GetHomepageDataQueryVariables,
  GetLayoutDataDocument,
  GetLayoutDataQuery,
  GetLayoutDataQueryVariables,
} from "src/generated/datocms-types";
import { getCollectionProducts } from "src/shared/utils/products";

export default HomePage;
export const getStaticProps: GetStaticProps<Props> = async ({
  preview = false,
}) => {
  const apolloClient = initializeApollo();
  const { data: layoutData } = await apolloClient.query<
    GetLayoutDataQuery,
    GetLayoutDataQueryVariables
  >({
    query: GetLayoutDataDocument,
    context: {
      preview,
    },
  });
  const { data: pageData } = await apolloClient.query<
    GetHomepageDataQuery,
    GetHomepageDataQueryVariables
  >({
    query: GetHomepageDataDocument,
    context: {
      preview,
    },
  });
  let collectionProducts = {};
  let blockProductCollection = null;
  pageData.homepage?.sections.forEach((s) => {
    s.content.forEach((c) => {
      if (c._modelApiKey === "block_product_collection") {
        blockProductCollection = (c as BlockProductCollectionRecord).collection;
      }
    });
  });
  if (blockProductCollection) {
    collectionProducts = await getCollectionProducts(
      [blockProductCollection],
      apolloClient,
      preview
    );
  }
  return {
    props: {
      collectionProducts,
      pageData,
      layoutData,
      preview,
    },
  };
};
